<template>
  <div class="app-container">
    <eHeader
      :query="query"
      :tableStatus="tableStatus"
      :pay-type-list="statistics.payTypes"
      @toQuery="toQuery"
      @clear="clear"
    />
    <!--表格渲染-->
    <el-table
            v-loading="loading"
            :data="data"
            size="small"
            stripe
            style="width: 100%;"
    >
      <el-table-column prop="transactionId" label="订单号"/>
      <el-table-column prop="MT4" label="MT账号">
        <template slot-scope="scope">
            <span v-if="scope.row.login!=null">{{scope.row.login}}<br></span>
            <span v-if="scope.row.group!=null">{{scope.row.group}}</span>
            <span v-if="scope.row.login==null&&scope.row.group==null">无</span>
        </template>
      </el-table-column>
      <el-table-column  label="客户信息" width="150">
        <template slot-scope="scope">
            <span v-if="scope.row.userName!=null">{{scope.row.userName}}<br></span>
          <span v-if="scope.row.custId!=null">{{scope.row.custId}}<br></span>
          <span v-if="scope.row.email!=null">{{scope.row.email}}</span>
          <span v-if="scope.row.custId==null&&scope.row.phone==null&&scope.row.email==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.managerId!=null">{{scope.row.managerId}}</span>
            <span v-if="scope.row.managerName==null&&scope.row.managerId==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="payType" label="支付渠道">
         <template slot-scope="scope">
          <span>{{ scope.row.payDetail }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="currency" label="支付币种"/>
      <el-table-column prop="amount" label="金额 ($)"/>
      <el-table-column prop="payStatus" label="支付状态">
        <template slot-scope="scope">
          <span>{{ parsePayStatus(scope.row.payStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <span>{{ scope.row.payStatus==3?parseDepositStatus(scope.row.status):'--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="提交时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.statusTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">
            <check v-if="checkPermission(['ADMIN','DEPOSIT_ALL','DEPOSIT_VIEW'])" class="my-button" :data="scope.row" :sup_this="sup_this" :isCheck="false"/>
            <check
                    v-if="checkPermission(['ADMIN','DEPOSIT_ALL','DEPOSIT_CHECK'])&&tableStatus!='-1'&&tableStatus!='6'&&tableStatus!='99'"
                    class="my-button"
                    :refresh="refresh"
                    :data="scope.row"
                    :sup_this="sup_this"
                    :isCheck="true"
            />
            <el-dropdown
                    size="mini"
                    v-if="getBtnAll(scope.row)"
            >
                <el-button size="mini" type="success">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                            class="dropdown-item"
                            v-if="checkPermission(['ADMIN','DEPOSIT_ALL','DEPOSIT_STATUS_UPDATE'])&&tableStatus=='-1'"
                    >
                        <div @click="doStatus(scope.row)">审核状态调整</div>
                    </el-dropdown-item>
                    <el-dropdown-item
                            class="dropdown-item"
                            v-if="payStatusModify(scope.row)"
                    >
                        <div @click="doPayStatus(scope.row)">支付状态调整</div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
      <public-pagination
              :total-page="total"
              :current-page="page"
              @pageChange="pageChange"
              @sizeChange="sizeChange"
      ></public-pagination>

    <el-dialog :append-to-body="true" :visible.sync="statusLoading" title="状态调整" width="500px">
     <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="申请编号">
        <el-input v-model="form.transactionId" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改前状态">
        <el-input v-model="form.befStatusDesc" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改后状态">
         <el-select v-model="form.updateStatus" placeholder="请选择状态">
          <el-option
            v-for="item in depositStatusOptions"
            :key="item.key"
            :label="item.display_name"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" :rows="2" style="width: 250px;"/>
      </el-form-item>
    </el-form>
    <div lot="footer" class="dialog-footer" style="text-align: right;">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">调整</el-button>
    </div>
  </el-dialog>

  <el-dialog :append-to-body="true" :visible.sync="payStatusLoading" title="支付状态修改" width="500px">
     <el-form ref="form" :model="payStatusForm" size="small" label-width="120px">
      <el-form-item label="申请编号">
        <el-input v-model="payStatusForm.transactionId" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改前状态">
        <el-input v-model="payStatusForm.befStatusDesc" style="width: 250px;" :disabled="true"/>
      </el-form-item>
      <el-form-item label="修改后状态">
         <el-select v-model="payStatusForm.updateStatus" placeholder="请选择状态">
          <el-option
            v-for="item in payStatusOptions"
            :key="item.key"
            :label="item.display_name"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付凭证">
        <el-upload
            class="upload"
            action="''"
            :accept="getLimitUploadImageFormat()"
            :file-list="fileList"
            :http-request="handleSuccess"
            :before-upload="beforeUpload"
            :on-preview="handlePreview"
            :limit=1
            list-type="picture"
        >
<!--          :on-success="handleSuccess"-->
            <el-button size="small" type="primary">点击上传查账凭证</el-button>
            <div slot="tip" class="el-upload__tip"><uploadImgTips></uploadImgTips></div>
          </el-upload>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" :rows="2" style="width: 250px;"/>
      </el-form-item>
    </el-form>
    <div lot="footer" class="dialog-footer" style="text-align: right;">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmitPayStatus">修改</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import { uploadFile } from '@/api/data'
import { getLimitUploadImageFormat,limitImageSizeFormat,getBase64, base64toFile,gePayTypeCommon,parseTime } from '@/utils/index'
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { modifyStatus,modifyPayStatus } from '@/api/cus/tCustDepositWithdraw'
import editcommi from './edit'
import check from './view'
import eHeader from './header'
import { parseDepositStatus,parseCurrencyType,parsePayType,parsePayStatus } from '@/views/pt/ib/utils/index'
export default {
  components: {
    eHeader,
    editcommi,
    check,
  },
  mixins: [initData],
  name: 'depositTable',
  props:{
     tableStatus: {
      type: Number,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'depositStatusOptions',
      'payStatusOptions'
    ])
  },
  data() {
    return {
      loading: false,
      payStatusLoading: false,
      statusLoading: false,
      sup_this: this,
      selectedRow:null,
      form: {
        id: '',
        transactionId: '',
        chkStatus: 1,
        updateStatus:1,
        befStatusDesc:'',
        remark:''
      },
      fileList:[],
      payStatusForm: {
        id: '',
        transactionId: '',
        chkStatus: 1,
        updateStatus:1,
        befStatusDesc:'',
        payImg:'',
        remark:''
      },
      statistics: {
        payTypes: []
      },
    }
  },
  created() {
    this.$nextTick(() => {
      this.init();
    })
  },
  watch:{
    refresh( newData,oldData){
      this.beforeInit()
      this.init()
    }
  },
  methods: {
    getLimitUploadImageFormat,
    gePayTypeCommon,
    parseTime,
    checkPermission,
    parseDepositStatus,
    parseCurrencyType,
    parsePayStatus,
    parsePayType,
    beforeUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    getBtnAll(row){
      return (checkPermission(['ADMIN','DEPOSIT_ALL','DEPOSIT_STATUS_UPDATE'])&& row.tableStatus=='-1') ||  this.payStatusModify(row);
    },
    payStatusModify(item){
      return this.checkPermission(['ADMIN','MODIFY_PAY_ALL','MODIFY_PAY_EDIT']) && this.tableStatus=='-1';
    },
    beforeInit() {
      this.url = '/crm/tCustDepositWithdraw'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort, type:1 }
      const query = this.query
      const transactionId = query.transactionId
      const login = query.login
      const status = query.status
      const payType = query.payType
      const createTime = this.parseTime(query.createTime)
      const updateTime = this.parseTime(query.updateTime)
      const chkStatus = query.chkStatus
      const managerInfo = query.managerInfo
      if (this.tableStatus!='-1') {
        this.params['status'] = this.tableStatus
        this.params['payStatus'] = 3
      }else if (chkStatus!=null&&chkStatus!='') {
        this.params['status'] = chkStatus
      }
      if (managerInfo!=null && managerInfo!='') { this.params['managerInfo'] = managerInfo }
      if (this.query.custInfo!=null && this.query.custInfo!='') { this.params['custInfo'] = this.query.custInfo };
      if (transactionId!=null && transactionId!='') { this.params['transactionId'] = transactionId }
      if (login!=null && login!='') { this.params['login'] = login }
      if (status!=null && status!='') { this.params['status'] = status }
      if (payType!=null && payType!='') {
        this.params['payTypes'] = this.gePayTypeCommon(payType);
      }
      if (createTime!=null && createTime!='') { this.params['createTime'] = createTime }
      if (updateTime!=null && updateTime!='') { this.params['updateTime'] = updateTime };
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      return true;
    },
    handleSuccess(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.payStatusForm.payImg = res.data.content[0].url;
        });
      });
    },
    handlePreview(file) {
      console.log(file);
    },
    doStatus(selectedRow){
      this.statusLoading=true
      this.selectedRow=selectedRow
      this.form={
        id: selectedRow.id,
        transactionId: selectedRow.transactionId,
        status: selectedRow.status,
        updateStatus:selectedRow.status,
        remark:''
      }
      for (var i = this.depositStatusOptions.length - 1; i >= 0; i--) {
        if(this.depositStatusOptions[i].key==selectedRow.status){
          this.form.befStatusDesc=this.depositStatusOptions[i].display_name
        }
      }
    },
    doPayStatus(selectedRow){
      this.payStatusLoading=true
      this.selectedRow=selectedRow
      this.payStatusForm={
        id: selectedRow.id,
        transactionId: selectedRow.transactionId,
        status: selectedRow.payStatus,
        updateStatus:selectedRow.payStatus,
        remark:''
      }
      for (var i = this.payStatusOptions.length - 1; i >= 0; i--) {
        if(this.payStatusOptions[i].key==selectedRow.payStatus){
          this.payStatusForm.befStatusDesc=this.payStatusOptions[i].display_name
        }
      }
    },
    cancel(){
      this.statusLoading=false
      this.payStatusLoading=false
      this.form = {
        id: '',
        transactionId:'',
        chkStatus: 1,
        updateStatus:1,
        befStatusDesc:'',
        remark:''
      }
      this.payStatusForm = {
        id: '',
        transactionId: '',
        chkStatus: 1,
        updateStatus:1,
        befStatusDesc:'',
        payImg:'',
        remark:''
      },
      this.selectedRow=null,
      this.fileList = []
    },
    doSubmitPayStatus(){
      this.loading=true
      if(this.payStatusForm.updateStatus==3&&(this.payStatusForm.payImg==null || this.payStatusForm.payImg=='')){
        this.$notify({
          title: '调整为支付成功，需上传查账凭证',
          type: 'error',
          duration: 2500
        })
        this.loading = false
        return;
      }
      const params = { id: this.selectedRow.id, payStatus: this.payStatusForm.updateStatus,payPic:this.payStatusForm.payImg}

      //接口调用
      modifyPayStatus(params).then(res => {
        this.$notify({
          title: '支付状态修改成功',
          type: 'success',
          duration: 2500
        })
        this.cancel()
        this.loading = false
        this.refresh=!this.refresh
      }).catch(err => {
        this.cancel()
        this.loading = false
      })
    },
    doSubmit(){
      this.loading=true
      const params = { id: this.selectedRow.id, status: this.form.updateStatus}
      if(this.form.remark!=null&&this.form.remark!=''){
        params['auditContent']=this.form.remark
      }
      //接口调用
      modifyStatus(params).then(res => {
        this.$notify({
          title: '调整成功',
          type: 'success',
          duration: 2500
        })
        this.cancel()
        this.loading = false
        this.refresh=!this.refresh
      }).catch(err => {
        this.cancel()
        this.loading = false
      })
    },
    getAuditTime(auditTime){
      if(auditTime){
        return parseTime(auditTime);
      }else {
        return '-'
      }
    },
    clear(){
      this.query = {};
      this.toQuery();
    }
  },
}
</script>

<style scoped>

</style>
