<template>
    <div class="head-container">
        <!-- 搜索 -->
        <el-select
                v-model="query.status"
                clearable
                placeholder="入金状态"
                class="filter-item"
                style="width: 130px"
                @change="toQuery"
        >
            <el-option v-for="item in depositStatusOptions" :key="item.key" :label="item.display_name"
                       :value="item.key"/>
        </el-select>
        <!--    query.payType-->
        <el-cascader
                size="mini"
                class="filter-item"
                v-model="query.payType"
                style="width: 230px;"
                placeholder="支付渠道"
                :options="cascaderOption"
                :props="props"
                :collapse-tags="true"
                clearable
                @change="toQuery"
        ></el-cascader>
        <el-input v-model="query.custInfo" clearable placeholder="客户姓名/ID" style="width: 200px;" class="filter-item"
                  @keyup.enter.native="toQuery"/>
        <el-input
                v-model="query.transactionId"
                clearable placeholder="订单号" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
        <el-input
                v-model="query.login"
                clearable
                placeholder="MT账号"
                style="width: 200px;"
                class="filter-item"
                @keyup.enter.native="toQuery"
                @keyup.native="loginInputHandle"
        />
        <el-input v-model="query.managerInfo" clearable placeholder="客户经理姓名/ID" style="width: 200px;"
                  class="filter-item" @keyup.enter.native="toQuery"/>
        <div class="filter-item">
            申请时间：
            <el-date-picker
                    v-model="query.createTime"
                    type="datetime"
                    placeholder="开始日期"
            >
            </el-date-picker>
            至
            <el-date-picker
                    v-model="query.updateTime"
                    type="datetime"
                    placeholder="结束日期">
            </el-date-picker>
        </div>
        <div class="filter-item">
            审核通过时间：
            <el-date-picker
                    v-model="query.startAuditTime"
                    type="datetime"
                    placeholder="开始日期"
            >
            </el-date-picker>
            至
            <el-date-picker
                    v-model="query.endAuditTime"
                    type="datetime"
                    placeholder="结束日期">
            </el-date-picker>
        </div>
        <el-button
                class="filter-item"
                size="mini"
                type="primary"
                icon="el-icon-search"
                @click="toQuery"
        >搜索</el-button>
        <el-button
                class="filter-item"
                size="mini"
                type="primary"
                icon="el-icon-close"
                @click="clear"
        >清空</el-button>
    </div>
</template>

<script>
  import { getPayTypeSelectList } from '@/api/cus/tCustDepositWithdraw'
  import { mapGetters } from 'vuex'
  import checkPermission from '@/utils/permission' // 权限判断函数
  export default {
    props: {
      query: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        cascaderOption: [
          {
            value: 1,
            label: '渠道',
            children: []
          },
          {
            value: 36,
            label: '佣金转入'
          },
          {
            value: 4,
            label: '其他',
            children: [
              {
                value: 35,
                label: 'COMMI_BONUS'
              },
              {
                value: 38,
                label: '赠金'
              },
              {
                value: 99,
                label: '其他'
              }
            ]
          }
        ],
        props: { multiple: true, expandTrigger: 'hover' }
      }
    },
    created() {
      this.getPayTypeList()
    },
    computed: {
      ...mapGetters([
        'depositStatusOptions'
      ])
    },
    methods: {
      checkPermission,
      toQuery() {
        this.$emit('toQuery')
      },
      loginInputHandle() {
        this.$emit('loginInputHandle')
      },
      getPayTypeList() {
        getPayTypeSelectList({
          type: 1
        }).then((res) => {
          let tempArr = []
          for (let key in res) {
            if (res.hasOwnProperty(key)) {
              tempArr.push({
                value: key,
                label: res[key]
              })
            }
          }
          this.cascaderOption[0].children = tempArr
        })
      },
      clear(){
        this.$emit('clear');
      }
    }
  }
</script>
