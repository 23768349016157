<template>
  <div>
    <el-dialog
      :append-to-body="true"
      v-if="dialog"
      :visible.sync="dialog"
      :title="isCheck ? '入金审核' : '入金信息'"
      width="800px"
      :before-close="cancel"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane label="申请信息" name="first">
          <el-row>
            <el-col><span class="label">申请单号:</span> <span>{{ data.transactionId }}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><span class="label">客户ID:</span> <span>{{ data.custId || '' }}</span></el-col>
            <el-col :span="8"><span class="label">邮箱: </span> <span>{{data.email}}</span></el-col>
            <el-col :span="8"><span class="label">电话号码: </span> <span>{{data.phone}}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><span class="label">MT4帐号:</span> <span>{{ data.login}}</span></el-col>
            <el-col :span="8"><span class="label">MT4分组: </span> <span>{{data.group}}</span></el-col>
            <el-col :span="8"><span class="label">MT4杠杆: </span> <span>{{data.leverage}}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><span class="label">入金金额: </span><span>{{ data.amount }}</span></el-col>
            <el-col :span="8"><span  class="label">支付方式: </span><span>{{ data.payDetail }}</span></el-col>
            <el-col :span="8"><span class="label">入金货币: </span><span>{{ data.currency }}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><span class="label">审核状态:</span> <span>{{ parseDepositStatus(data.status) }}</span></el-col>
            <el-col :span="8"><span class="label">申请时间: </span> <span>{{ parseTime(data.createTime) }}</span></el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="申请人信息" name="second" :loading="loadingUser" >
            <el-row>
              <el-col :span="8"><span class="label">用户名:</span> <span>{{ useInfo.userName ||' ' }}</span></el-col>
              <el-col :span="8"><span class="label">国籍: </span> <span>{{ useInfo.nationName}}</span></el-col>
              <el-col :span="8"><span class="label">生日: </span> <span>{{useInfo.birthday}}</span></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"><span class="label">电话号码: </span> <span>{{ useInfo.phone}}</span></el-col>
              <el-col :span="8"><span class="label">邮箱:</span> <span>{{ useInfo.email }}</span></el-col>
            </el-row>
            <el-row>
              <el-col><span class="label">家庭住址:</span> <span>{{useInfo.homeProvince }}-{{useInfo.homeCity }}-{{useInfo.homeStreet }}-{{useInfo.homeAddress }}</span></el-col>
            </el-row>
        </el-tab-pane>
        <el-tab-pane label="审核记录" name="thirdly">
          <audit-log-table
            :tabel-data="events"
            @preview="previewImg"
          ></audit-log-table>
        </el-tab-pane>
      </el-tabs>
      <div v-if="isCheck" slot="footer" class="dialog-footer">
        <money-audit ref="moneyaudit"></money-audit>
        <el-button type="text" @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit(1)">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :append-to-body="true" :visible.sync="imgUrlShow" width="800px">
      <img :src="imgUrl" width="100%">
    </el-dialog>
  </div>
</template>
<script>
  import moneyAudit from '@/components/public/moneyAudit'
  import auditLogTable from '@/components/public/auditLogTable'
  import withdrawInfo from '@/components/public/withdrawInfo'
import { audit} from '@/api/cus/tCustDepositWithdraw'
import { parseTime } from '@/utils/index'
import checkPermission from '@/utils/permission'
import { parseDepositStatus,parsePayType } from '@/views/pt/ib/utils/index'
import { initData } from '@/api/data'
import { mapGetters } from 'vuex'

export default {
  components:{
    withdrawInfo,
    auditLogTable,
    moneyAudit,
  },
  props: {
    isCheck: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      dialog: false ,
      events: [],
      useInfo:{},
      imgUrls:[],
      activeName: 'first',
      imgUrl:'',
      loadingUser:false,
      imgUrlShow: false,

    }
  },
  created(){
    if (this.payTypeOptions == null) {
      this.$store.dispatch("GetPayType");
    }
  },
  computed: {
    ...mapGetters([
      'payTypeOptions'
    ]),
    backInfo(){
      if(this.data){
        if(this.data.snapshotList){
          for (let i = 0; i < this.data.snapshotList.length; i++) {
            if(this.data.snapshotList[i].payTypeId == 4){
              return this.data.snapshotList[i].custPayDetail;
              break;
            }
          }
        }
      }else {
        return {
          bankAccount: '',
          bankName: '',
          bankOutlet: '',
          swiftCode: '',
          bankOutletProvince: '',
          bankOutletCity: '',
        }
      }
    },
    usdtInfo(){
      if(this.data){
        if(this.data.snapshotList){
          for (let i = 0; i < this.data.snapshotList.length; i++) {
            if(this.data.snapshotList[i].payTypeId == 5){
              return this.data.snapshotList[i].custPayDetail;
              break;
            }
          }
        }
      }else {
        return {
          address: '',
          qrPic: '',
        }
      }
    },
    otcInfo(){
      if(this.data){
        if(this.data.snapshotList){
          for (let i = 0; i < this.data.snapshotList.length; i++) {
            if(this.data.snapshotList[i].payTypeId == 6){
              return this.data.snapshotList[i].custPayDetail;
              break;
            }
          }
        }
      }else {
        return {
          address: '',
          qrPic: '',
        }
      }
    },
  },
  methods: {
    parseTime,
    checkPermission,
    parsePayType,
    parseDepositStatus,
    cancel(){
      this.loadingUser= false
      this.useInfo={};
      this.imgUrls=[];
      this.loading = false;
      this.dialog = false;
      this.activeName = 'first';
    },
    previewImg(imgUrl){
      this.imgUrl = imgUrl;
      this.imgUrlShow = true;
    },
    handleClick(tab, event) {
      if(tab.name == 'second' && this.useInfo.id == null) {
        this.loadingUser= true
        var url1 = 'crm/tCustInfo'
        var params1 = { id : this.data.custId,page: 0, size: 10}
        initData(url1,params1).then(res=>{
            const userInfo = res.content[0]
            this.useInfo = userInfo;
            this.loadingUser= false;
          }).catch(err => {
            this.loadingUser= false
            console.log(err.response)
          })
      }
    },
    doSubmit() {
      const params = {
        id: this.data.id,
        decision:this.$refs.moneyaudit.auditResult,
        auditBy:this.data.status,
      };
      if(this.$refs.moneyaudit.auditResult ==2){
        params.rejectReason = this.$refs.moneyaudit.rejectReason;
        if(this.$refs.moneyaudit.rejectReason == -1){
          params.rejectReason = this.$refs.moneyaudit.rejectReasonSelf;
        }
      }
      this.loading = true;
      audit(params).then(res => {
        this.$notify({
          title: '审核操作成功',
          type: 'success',
          duration: 2500
        })
        this.cancel();
        this.sup_this.init();
      }).catch(err => {
        this.loading = false;
      })
    },
  }
}
</script>

<style lang="less" scoped>
.el-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }
.el-col {
    border-radius: 4px;
  }
.label {
    font-size: 18px;
    padding: 10px 10px;
  }

</style>
